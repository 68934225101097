
import { defineComponent } from 'vue';
import { validateEmail } from '@/services/validation';
import { createPasswordReset } from '@/services/steamcord';

export default defineComponent({
  data() {
    return {
      loading: false,
      email: '',
      emailError: '',
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (this.loading) {
        return;
      }

      this.emailError = validateEmail(this.email);
      if (this.emailError) {
        return;
      }

      this.loading = true;

      await createPasswordReset(this.email);

      this.$router.push({ name: 'Reset Password Verify Email', params: { email: this.email } });
    },
  },
});
